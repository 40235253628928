import React from "react";
import styles from "./popup.module.css"
import cyberturkImage from './../cyberturk.png'


const PopUp = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className={styles["popup-overlay"]} onClick={onClose}>
            <div className={styles["popup-content"]} onClick={(e) => e.stopPropagation()}>
                <button className={styles["close-btn"]} onClick={onClose}>X</button>
                <h2>Congrats, You just bought your CYBERTURK</h2>
                <p>Your order will be delivered to you shortly.</p>
                <p>Your Account balance = -69 420$</p>
                <img src={cyberturkImage} alt="turk" />
            </div>
        </div>
    )
}

export default PopUp;