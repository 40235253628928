import React, { useState } from "react";
import './App.css';
import { SocialIcon } from 'react-social-icons';
import 'react-social-icons/x'
import 'react-social-icons/t.me';
import icon from './pumpfun.png'
import icon2 from './dexscreener.png'
import RotatingBanner from './Banner';
import RotatingImg from './car';
import PopUp from './Popup';
import ImgElon from './elon_old.png'


function App() {

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const openPopUp = () => {
    setIsPopUpOpen(true);
  };
  const closePopUp = () => {
    setIsPopUpOpen(false);
  };

  return (
    <div className="App">
        <RotatingBanner className="App-header" />
        <RotatingImg className="App-header-2"/>
      <div className='button-wrapper'>
      <img src={ImgElon} alt='Old_Elon' className='imgHug' />

        <button onClick={openPopUp} className='buy-now-btn'>
          BUY A CYBERTURK NOW <br /> PAY WITH USD
          </button>
        <PopUp isOpen={isPopUpOpen} onClose={closePopUp} />
        <button onClick={() => window.open('https://pump.fun/EoJYs7HENNG3RDGmfVXmTDdhP6vtEv31XVp7MyyAcQZJ', '_blank')} className='buy-now-btn'>
          BUY A CYBERTURK NOW <br /> PAY WITH $CYBER
        </button>
      </div>
      <div className="text-container">
        <h1>BUY YOUR OWN CYBERTURK, PAY WITH $CYBER</h1>
      </div>
      <div className="text-container-2">
      <p>CA: EoJYs7HENNG3RDGmfVXmTDdhP6vtEv31XVp7MyyAcQZJ</p>
      </div>
      <div className="text-container-3">
      <p>SOCIALS & WHERE TO BUY:</p>
      </div>
      <div className="icons-row">
        <SocialIcon url="https://twitter.com/CyberTurkSol" target="_blank" rel="noopener noreferrer" network="x" className="socialIcon" style={{ width: '50px', height: '50px' }}/>
        <SocialIcon url="https://t.me/+Tzf-D8v9NeA5Nzc0" target="_blank" rel="noopener noreferrer" network="t.me" className="styles.socialIcon" style={{ width: '50px', height: '50px' }}/>
        <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer" className="clickable-icon">
          <img src={icon2} alt="icon" style={{ width: '50px', height: '50px', marginLeft: '25px' }} />
        </a>
        <a href="https://pump.fun/EoJYs7HENNG3RDGmfVXmTDdhP6vtEv31XVp7MyyAcQZJ" target="_blank" rel="noopener noreferrer" className="clickable-icon">
          <img src={icon} alt="icon" style={{ width: '50px', height: '50px' }} />
        </a>
      </div>
    </div>
  );
}

export default App;
