import React from 'react'
import styles from './car.module.css'
import cyberturkImage from './../cyberturk.png'


const RotatingImg = ({ imageUrl }) => {
    return (
        <div className={styles["rotating-image-container"]}>
            <img src={cyberturkImage} alt="cyber" className={styles['rotating-image']} />
        </div>
    )
}

export default RotatingImg;