import React from "react";
import styles from "./banner.module.css"

const RotatingBanner = ({ className }) => {

    const textToRepeat = "BUY A CYBERTURK $$$";
    const repeatCount = 20;

    const textElements = Array.from({ length: repeatCount }, (_, index) => (
        <span key={index}>{textToRepeat}</span>
    ));

    return (
    <div className={`${styles['rotating-banner']} ${className}`}>
        <div className={styles['banner-wrapper']} >
            <div className={styles['rotating-text']}>
            {textElements}
            </div>
        </div>
    </div>
    )
}

export default RotatingBanner;